import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import Page from "../components/layout/page.component";
import Layout from "../components/layout/layout.component";
import Titantron from "../components/titantron/titantron.component";
import secondaryVideo from "../siteContent/secondaryPage/videos/secondaryVideo.mp4";
import PictureArticle from "../components/content/pictureArticle.component";
import { graphql } from "gatsby";

Referenzen.propTypes = {
  data: PropTypes.object,
};

function Referenzen(props) {
  const getPosts = () => {
    return props.data.allMarkdownRemark.edges.map(
      ({ node, node: { frontmatter } }, index) => {
        console.log(node);
        return (
          <PictureArticle
            key={node.id}
            headline={frontmatter.title}
            text={node.html}
            imageFluid={frontmatter.featuredImage.childImageSharp.fluid}
            altText={node.frontmatter.altText}
            alignPicture={index % 2 === 0 ? "left" : "right"}
            description={`${frontmatter.date}: ${frontmatter.description}`}
          />
        );
      }
    );
  };

  return (
    <Page titlePrefix={"Referenzen"}>
      <Helmet>
        <meta
          name="description"
          content="Veranstaltungen bei denen Mollton selbst tätig war oder mitgeholfen hat."
        />
      </Helmet>
      <Layout background="dark" width="full">
        <Titantron
          title="Referenzen"
          underline=""
          video={secondaryVideo}
          underline={
            "Veranstaltungen bei denen Mollton selbst tätig war oder mitgeholfen hat"
          }
        />
      </Layout>
      <Layout width="twothird" background="trans">
        {getPosts()}
      </Layout>
    </Page>
  );
}

export default Referenzen;

export const query = graphql`
  {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "referenz" } } }) {
      edges {
        node {
          id
          frontmatter {
            date
            title
            subtitle
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
    }
  }
`;
